var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"cl_main2"},[_vm._m(2),_c('div',{staticClass:"processBox"},[_c('div',{staticClass:"timelineProcessBox"},[_c('el-timeline',{staticClass:"timeline"},_vm._l((_vm.activities),function(activity,index){return _c('el-timeline-item',{key:index,staticClass:"lineitem",class:activity.done ? 'active' : 'inactive',attrs:{"timestamp":activity.time}},[_c('span',{staticStyle:{"display":"flex","flex-direction":"column","margin-top":"40px"}},[_c('span',{staticStyle:{"margin":"10px 0","font-size":"26px","color":"#3a60f7"}},[_vm._v(" "+_vm._s(activity.content)+" ")])])])}),1)],1)])]),_vm._m(3),_c('footer',{staticClass:"footer-content"},[_c('div',{staticClass:"footer-main clearfix"},[_c('div',{staticClass:"footerLogo fl"},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":require("../assets/images/logo-footer.png"),"alt":""}}),_c('ul',{staticClass:"imgList"},[_vm._m(4),_c('li',{staticClass:"fl"},[_c('el-popover',{attrs:{"placement":"bottom-start","width":"200","trigger":"hover","open-delay":300,"content":"+62 877-8951-1634"}},[_c('img',{staticClass:"wxIcon",staticStyle:{"width":"24px","height":"24px"},attrs:{"slot":"reference","src":require("../assets/images/whats-app.png"),"alt":""},slot:"reference"})])],1)])]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]),_c('div',{staticClass:"copyright"},[_vm._v(" © 2018 All rights reserved   PTbujingyun ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bannerH"},[_c('div',{staticClass:"bflex"},[_c('p',{staticClass:"h3"},[_vm._v("About Us")]),_c('p',[_vm._v(" Bujingyun is committed to being a global intelligent cloud communications service provider ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main1"},[_c('div',{staticClass:"mainNews"},[_c('div',{staticClass:"news-flex"},[_c('div',{staticClass:"news-m"},[_c('img',{attrs:{"src":require("../assets/images/cl_Introduct.png"),"alt":""}})]),_c('p',{staticClass:"intro-head"},[_vm._v("Company introduction")])])]),_c('form',{staticClass:"el-form cl-scen-form"},[_c('div',{staticClass:"cl-col-right-zh el-col el-col-24"},[_c('div',{staticClass:"el-carousel el-carousel--vertical"},[_c('div',{staticClass:"el-carousel__container",staticStyle:{"height":"350px"}},[_c('div',{staticClass:"el-carousel__item is-animating"},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/images/cl_intro_1.png"),"alt":""}})]),_c('p',{staticStyle:{"line-height":"33px"}},[_vm._v(" Bujingyun was founded in 2018, with Indonesia as the core of its headquarters development, and set up branches in Singapore, Hong Kong, China and Shanghai, China. The company is committed to becoming the world's leading intelligent cloud communication service provider, providing notification and marketing management solutions by integrating one-stop messaging, voice lines, international SMS and other communication functions to meet the mobile communication and marketing needs of enterprises on a global scale, helping enterprises and users to achieve smoother communication. Based on its strong technical strength and years of accumulated customer service experience, Bujingyun has a significant advantage in the field of intelligent cloud communications ")]),_c('p',{staticClass:"p2"}),_c('p',{staticClass:"p3"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cl_main_center"},[_c('div',{staticClass:"cl_top"},[_c('img',{attrs:{"src":require("../assets/images/cl_pro.png"),"alt":""}})]),_c('p',{staticClass:"cl-team-header cl-head",staticStyle:{"margin-right":"10px"}},[_vm._v(" Milestone ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main2"},[_c('div',{staticClass:"mainCenter"},[_c('p',{staticClass:"title"},[_vm._v("Are you the one we've been waiting for?")]),_c('p',{staticClass:"text"},[_vm._v(" We believe there is a strong attraction between like-minded people. If you are interested in us and would like to join us, or want to get to know us better, please contact us today! ")]),_c('a',{attrs:{"href":"/#/contact"}},[_c('div',{staticClass:"contact-us"},[_vm._v("Contact us")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"fl"},[_c('a',{attrs:{"href":"https://join.skype.com/invite/rtfRERIQoTmf","target":"_blank"}},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require("../assets/images/skype.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"footer-products fl"},[_c('li',[_vm._v("Products")]),_c('li',[_vm._v("Short message")]),_c('li',[_vm._v("Sipturnk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"footer-products fl"},[_c('li',[_vm._v("Developer")]),_c('li',{staticClass:"faq"},[_vm._v("User service agreement")]),_c('li',{staticClass:"faq"},[_vm._v("Privacy Policy")]),_c('li',{staticClass:"faq"},[_vm._v("Source security commitment")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"footer-products fl"},[_c('li',[_vm._v("Company information")]),_c('li',[_c('a',{staticStyle:{"color":"#465980"},attrs:{"href":"/#/about-us"}},[_vm._v("About us")])]),_c('li',[_vm._v("Enterprise dynamics")]),_c('li',[_vm._v("Join us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"footer-products fl"},[_c('li',[_vm._v("Contact us")]),_c('li',[_vm._v("Alex.jiang@bujingyun.vip")]),_c('li',[_vm._v("PTbujingyun")])])
}]

export { render, staticRenderFns }